import { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { cockpitUrl } from '../utils/cockpit.js';
import * as _ from 'underscore';

const canvasWidth = 512;
let mouseIsDown = false;

const saveCanvas = (poem) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const newCanvas = document.createElement('canvas');
  newCanvas.width = canvasWidth;
  const userCanvas = document.querySelector('.highlighter-canvas');
  const receivedCanvas = document.querySelector('.highlighter-canvas--received');

  if (userCanvas && receivedCanvas) {
    newCanvas.height = userCanvas.height;
    const ctx = newCanvas.getContext('2d');
    ctx.drawImage(receivedCanvas, 0, 0);
    ctx.drawImage(userCanvas, 0, 0);

    const dataUrl = newCanvas.toDataURL();

    const updatedPoem = poem;
    updatedPoem.data_url = dataUrl;

    fetch(`${cockpitUrl}/api/collections/save/poems?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: updatedPoem
      })
    })
    .then(res => res.json())
    .catch(error => console.log(error));
  }
}

// const clearCanvases = (poem) => {
//   const canvases = document.querySelectorAll('.drawing-canvas');
//   if (canvases[0]) {
//     for (let canvas of canvases) {
//       const ctx = canvas.getContext('2d');
//       ctx.clearRect(0, 0, canvas.width, canvas.height);
//     }
//     saveCanvas(poem);
//   }
// }

const drawToCanvas = (x, y, emit, socketEmit, poem, touching, scrollAmount) => {

  let addTop = 0;
  let addLeft = 0;

  if (mouseIsDown === true) {
    let canvas = document.querySelector('.highlighter-canvas');
    const sectionInner = document.querySelector('.section__inner');
    const poemTextOuter = document.querySelector('.poem__text__outer');
    const poemContent = document.querySelector('.poem__content');
    const poemContentInner = document.querySelector('.poem__content__inner');
    const largeTitle = document.querySelector('.poem__title--large');

    if (poemContentInner) {
      addLeft = 0 - poemContentInner.offsetLeft;
    }

    if (sectionInner && poemTextOuter) {
      if (touching === false) {
        const poemContentPaddingTop = parseFloat(window.getComputedStyle(poemContent).paddingTop);
        addTop = addTop - sectionInner.offsetTop - poemContentPaddingTop;

        if (largeTitle) {
          addTop -= largeTitle.offsetHeight;
        }

      } else {
        const sectionInnerPaddingTop = parseFloat(window.getComputedStyle(sectionInner).paddingTop);
        const poemContentPaddingTop = parseFloat(window.getComputedStyle(poemContent).paddingTop);
        addTop = addTop + scrollAmount - sectionInnerPaddingTop - poemContentPaddingTop;

        if (largeTitle) {
          addTop -= largeTitle.offsetHeight;
        }
      }
    }

    let xMod = Math.floor(((x + addLeft) / canvas.offsetWidth) * canvasWidth);
    let yMod = Math.floor(((y + addTop) / canvas.offsetWidth) * canvasWidth);

    // console.log(xMod, yMod);

    const context = canvas.getContext('2d');
    context.fillStyle = "rgba(255,255,0,0.1)";
    context.fillRect(xMod - 6, yMod - 6, 12, 12);

    if (!emit) { return; }

    if (socketEmit) {
      socketEmit('drawing', {
        x: xMod,
        y: yMod,
        poem: poem.title_slug
      });
    }
  }
}

const drawToCanvasCursor = (x, y, scrollAmount, touching) => {

  let addTop = 0;
  let addLeft = 0;

  let canvas = document.querySelector('.highlighter-canvas--cursor');
  const sectionInner = document.querySelector('.section__inner');
  const poemTextOuter = document.querySelector('.poem__text__outer');
  const poemContent = document.querySelector('.poem__content');
  const poemContentInner = document.querySelector('.poem__content__inner');
  const largeTitle = document.querySelector('.poem__title--large');

  if (poemContentInner) {
    addLeft = 0 - poemContentInner.offsetLeft;
  }

  if (sectionInner && poemTextOuter) {
    if (touching === false) {
      const poemContentPaddingTop = parseFloat(window.getComputedStyle(poemContent).paddingTop);
      addTop = addTop - sectionInner.offsetTop - poemContentPaddingTop;

      if (largeTitle) {
        addTop -= largeTitle.offsetHeight;
      }

    } else {
      const sectionInnerPaddingTop = parseFloat(window.getComputedStyle(sectionInner).paddingTop);
      const poemContentPaddingTop = parseFloat(window.getComputedStyle(poemContent).paddingTop);
      addTop = addTop + scrollAmount - sectionInnerPaddingTop - poemContentPaddingTop;

      if (largeTitle) {
        addTop -= largeTitle.offsetHeight;
      }
    }
  }

  let xMod = Math.floor(((x + addLeft) / canvas.offsetWidth) * canvasWidth);
  let yMod = Math.floor(((y + addTop) / canvas.offsetWidth) * canvasWidth);

  // console.log(xMod, yMod);

  const context = canvas.getContext('2d');
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = "rgba(255,255,0,0.4)";
  context.fillRect(xMod - 6, yMod - 6, 12, 12);
}

const drawToCanvasReceived = (data) => {
  const { x, y, poem } = data;

  const canvas = document.querySelector('.highlighter-canvas--received');
  const sectionInner = document.querySelector('.section__inner');

  if (sectionInner.dataset.poem.replace(/"/g, '') === poem) {
    const context = canvas.getContext('2d');
    context.fillStyle = "rgba(255,255,0,0.05)";
    context.fillRect(x - 6, y - 6, 12, 12);
  }

}

const PoemHighlighter = (props) => {
  const { socketEmit, poem, scrollAmount } = props;
  const [isInitialised, setIsInitialised] = useState(false);
  const saveCanvasThrottled = _.throttle(() => { saveCanvas(poem) }, 1200);

  const handleMouseMove = useCallback((e) => {
    if ((e.clientX && e.clientY) || e.touches) {
      if (e.touches) {
        if (e.touches[0]) {
          drawToCanvasCursor(e.touches[0].clientX, e.touches[0].clientY, scrollAmount, true);
          if (mouseIsDown === true) {
            drawToCanvas(e.touches[0].clientX, e.touches[0].clientY, true, socketEmit, poem, true, scrollAmount);
          }
        }
      } else {
        drawToCanvasCursor(e.clientX, e.clientY, scrollAmount, false);
        if (mouseIsDown === true) {
          drawToCanvas(e.clientX, e.clientY, true, socketEmit, poem, false, scrollAmount);
        }
      }
    }
  }, [socketEmit, poem, scrollAmount]);

  const handleMouseMoveThrottled = _.throttle(handleMouseMove, 6);

  const handleMouseDown = useCallback((e) => {
    mouseIsDown = true;
  }, []);

  const handleMouseUp = useCallback((e) => {
    if (mouseIsDown === false) { return; }
    mouseIsDown = false;
    if ((e.clientX && e.clientY) || e.touches) {
      if (e.touches) {
        if (e.touches[0]) {
          drawToCanvas(e.touches[0].clientX, e.touches[0].clientY, true, socketEmit, poem, true, scrollAmount);
        }
      } else {
        drawToCanvas(e.clientX, e.clientY, true, socketEmit, poem, false, scrollAmount);
      }
    }
    saveCanvasThrottled(poem);
  }, [socketEmit, poem, saveCanvasThrottled, scrollAmount]);

  useEffect(() => {

    const canvasReceived = document.querySelector('.highlighter-canvas--received');

    const fetchPoemsData = () => {
      const apiKey = process.env.REACT_APP_API_KEY;
      fetch(`${cockpitUrl}/api/collections/get/poems?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {
          if (response.entries) {
            if (response.entries[0]) {
              for (let i = 0; i < response.entries.length; i++) {
                if (response.entries[i]._id) {
                  if (response.entries[i]._id === poem._id) {
                    const dataUrl = response.entries[i].data_url;
                    if (dataUrl && dataUrl !== '') {
                      const img = document.createElement('img');
                      img.src = dataUrl;
                      img.onload = () => {
                        canvasReceived.getContext('2d').drawImage(img, 0, 0);
                      }
                    }
                  }
                }
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    }

    const onWindowResize = () => {
      const poemTextOuter = document.querySelector('.poem__text__outer');
      const drawingCanvases = document.querySelectorAll('.drawing-canvas');
      if (drawingCanvases[0]) {
        for (let drawingCanvas of drawingCanvases) {
          drawingCanvas.width = canvasWidth;
          drawingCanvas.height = (poemTextOuter.offsetHeight / poemTextOuter.offsetWidth) * canvasWidth;
        }
      }
    }

    window.addEventListener('resize', onWindowResize);
    window.addEventListener('resize', fetchPoemsData);

    if (isInitialised === false) {
      setIsInitialised(true);
      onWindowResize();
      fetchPoemsData();
    }

    return () => {
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('resize', fetchPoemsData);
    }
  }, [poem, isInitialised]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{duration: .3}}
      className="highlighter__wrapper"
      onMouseMove={handleMouseMoveThrottled}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchMove={handleMouseMoveThrottled}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onBlur={handleMouseUp}
    >
      <div className="highlighter__wrapper__inner">
        <canvas className="drawing-canvas highlighter-canvas--received"></canvas>
        <canvas className="drawing-canvas highlighter-canvas"></canvas>
        <canvas className="drawing-canvas highlighter-canvas--cursor"></canvas>
        {/* <button className="reset-button" onClick={() => { clearCanvases(poem) }}>Reset</button> */}
      </div>
    </motion.div>
  )
}

export { drawToCanvas, drawToCanvasReceived, PoemHighlighter };