import { useState, useEffect } from 'react';
import { cockpitUrl } from '../utils/cockpit.js';
import parse from 'html-react-parser';

const PrivacyPolicy = (props) => {

  const { history, windowDimensions } = props;
  const { height } = windowDimensions;
  const [privacyPolicyData, setPrivacyPolicyData] = useState({});

  useEffect(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const fetchPrivacyPolicyData = () => {
      fetch(`${cockpitUrl}/api/singletons/get/privacy_policy?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {
          setPrivacyPolicyData(response);
        })
        .catch(error => {
          console.log(error);
        })
    }

    fetchPrivacyPolicyData();
  }, []);

  return (
    <div
      className="popup popup--privacy-policy"
      style={{
        height: (height - 140) + 'px'
      }}
    >
      <button
        onClick={() => {history.goBack()}}
        title="home"
        className="popup__button--close button--nav button--nav--close"
      >
        <div className="button--nav__inner">
          <div className="button--nav__line button--nav__line--0"></div>
          <div className="button--nav__line button--nav__line--1"></div>
        </div>
      </button>
      <div className="popup__inner">
        <div className="privacy-policy">
          {privacyPolicyData.content && parse(privacyPolicyData.content)}
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;