import { PayPalButton } from "react-paypal-button-v2";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { cockpitUrl } from '../utils/cockpit.js';
import { AnimatePresence, motion } from "framer-motion";

const PreOrderAddNote = (props) => {

  const { noteText, setNoteText } = props;

  return (
    <form className="add-a-note">
      <label htmlFor="note">Send them a note</label>
      <textarea
        name="note"
        className="add-a-note__input"
        value={noteText}
        onChange={(e) => {setNoteText(e.target.value)}}
      >
      </textarea>
    </form>
  )
}

const PreOrderPaypal = (props) => {

  const { price, noteIsActive, updatePaymentStatusToPaid, clientId, paymentStatusIsPaid, thankYouText } = props;
  const [noteText, setNoteText] = useState('');

  const createNewOrder = (data) => {
    const apiKey = process.env.REACT_APP_API_KEY;

    const { create_time, id, payer, purchase_units } = data;
    const { email_address, payer_id } = payer;
    const { given_name, surname } = payer.name;
    const { name, address } = purchase_units[0].shipping;
    const { full_name } = name;
    const { address_line_1, admin_area_1, admin_area_2, country_code, postal_code } = address;

    const shipping_address = full_name + ', ' + address_line_1 + ', ' + admin_area_1 + ', ' + admin_area_2 + ', ' + country_code + ', ' + postal_code;

    fetch(`${cockpitUrl}/api/collections/save/orders?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          name: given_name + ' ' + surname,
          email: email_address,
          time: create_time,
          purchase_id: id,
          payer_id: payer_id,
          shipping_address: shipping_address,
          note: noteText
        }
      })
    })
    .then(res=>res.json());
  }

  return (
    <div className="paypal__wrapper">
      <AnimatePresence>
      {
        paymentStatusIsPaid === false ?
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
            transition={{
              duration: 0.2
            }}
            className="paypal__inner"
            >
            {
              noteIsActive === true ?
                <PreOrderAddNote
                  noteText={noteText}
                  setNoteText={setNoteText}
                />
              :
                ''
            }
            <div className="paypal__buttons__wrapper">
              <PayPalButton
                amount={price}
                onSuccess={(data) => {
                  updatePaymentStatusToPaid();
                  createNewOrder(data);
                  console.log(data)
                }}
                options={{
                  clientId: clientId,
                  currency: 'GBP'
                }}
              />
            </div>
          </motion.div>
          :
          <motion.p
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
            transition={{
              duration: 0.2
            }}
            className="paypal__thank-you"
          >{thankYouText}</motion.p>
        }
        <Link className="link-to-privacy-policy" rel="noreferrer" target="_blank" to="/privacy-policy">Read the privacy policy</Link>
      </AnimatePresence>
    </div>
  )
}

export default PreOrderPaypal;