import parse from 'html-react-parser';

const Guide = (props) => {

  const { guide } = props;
  const { text } = guide;

  return (
    <div className="guide">
      <div className="guide__inner">
        <div className="guide__content">
          {text ? parse(text) : ''}
        </div>
      </div>
    </div>
  )

}

export default Guide;