import React, { useState, useEffect, useCallback } from 'react';
import {
  Route,
  Switch
} from 'react-router-dom'
import '../css/tachyons.min.css';
import '../css/App.css';
import { io } from "socket.io-client";

import Header from './components/Header.js';
import { Menu, MenuButton } from './components/Menu.js';
import Poem from './views/Poem.js';
import Guides from './components/Guides.js';
import { cockpitUrl } from './utils/cockpit.js';
import { AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import PreOrder from './views/PreOrder.js';
import PrivacyPolicy from './views/PrivacyPolicy.js';
import { drawToCanvasReceived } from './components/PoemHighlighter.js';
import { logoToParse } from './components/Logo';

const ENDPOINT = "https://socketio.thecycle.today";

const Content = (props) => {

  const onFirstTouch = () => {
    window.USER_IS_TOUCHING = true;
  }

  const router = props.location;
  const { socketEmit } = props;
  const { pathname } = props.history.location;
  const [scrollDirection, setScrollDirection] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [activePoemIndex, setActivePoemIndex] = useState(0);
  const [prevPoemPath, setPrevPoemPath] = useState("/");
  const [nextPoemPath, setNextPoemPath] = useState("/");
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [poemScrollAmount, setPoemScrollAmount] = useState(0);
  const [poemScrollHeight, setPoemScrollHeight] = useState(0);
  const [highlighterIsActive, setHighlighterIsActive] = useState(false);
  // use time or user's global dark mode setting to determine dark mode on startup
  const [darkModeIsActive, setDarkModeIsActive] = useState((parseFloat(new Date().getHours()) >= 19 || parseFloat(new Date().getHours()) < 7) || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? true : false);

  const [poems, setPoems] = useState([]);
  const [guides, setGuides] = useState([]);
  const [preorder, setPreOrder] = useState([]);
  const [guideIsActive, setGuideIsActive] = useState(true);
  const [initialised, setInitialised] = useState(false);
  const [disableScrollRouteChange, setDisableScrollRouteChange] = useState(false);

  const onWindowResize = useCallback(() => {
    const fullHeightElements = document.querySelectorAll('.full-height');
    if (fullHeightElements[0]) {
      for (let fullHeightElement of fullHeightElements) {
        fullHeightElement.style.height = window.innerHeight;
      }
    }
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, []);

  let scrollRouteChangeTimeout;

  const onScrollRouteChangeTimeout = () => {
    setDisableScrollRouteChange(false);
  }

  const setScrollRouteChangeTimeout = () => {
    clearTimeout(scrollRouteChangeTimeout);
    setDisableScrollRouteChange(true);
    setTimeout(onScrollRouteChangeTimeout, 900);
  }

  let uiTimeout;

  const showUI = () => {
    clearTimeout(uiTimeout);
  }

  const onLinkClick = (e) => {
    let target = e.target;
    if (!e.target.href) {
      target = target.closest('a');
    }
    if (target) {
      if (target.href) {
        setScrollDirection(0)
      }
    }
  }

  const handleChangeActivePoem = useCallback((allPoems) => {
    let slug = pathname.replace('/poem/', '');
    if (allPoems.entries) {
      for (let i = 0; i < allPoems.entries.length; i++) {
        if (allPoems.entries[i].title_slug === slug) {
          setActivePoemIndex(i);

          if (allPoems.entries[i + 1]) {
            setNextPoemPath('/poem/' + allPoems.entries[i + 1].title_slug);
          } else {
            setNextPoemPath('/poem/' + allPoems.entries[0].title_slug);
          }

          if (allPoems.entries[i - 1]) {
            setPrevPoemPath('/poem/' + allPoems.entries[i - 1].title_slug);
          } else {
            setPrevPoemPath('/poem/' + allPoems.entries[allPoems.entries.length - 1].title_slug);
          }
        }
      }
    }
  }, [pathname]);

  const switchPoem = useCallback((allPoems, index) => {
    if (allPoems.entries) {
      if (allPoems.entries[index]) {
        setActivePoemIndex(index);
        props.history.push('/poem/' + allPoems.entries[index].title_slug);
      } else {
        setActivePoemIndex(0);
      }

      if (allPoems.entries[index + 1]) {
        setNextPoemPath('/poem/' + allPoems.entries[index + 1].title_slug);
      } else {
        setNextPoemPath('/poem/' + allPoems.entries[0].title_slug);
      }

      if (allPoems.entries[index - 1]) {
        setPrevPoemPath('/poem/' + allPoems.entries[index - 1].title_slug);
      } else {
        setPrevPoemPath('/poem/' + allPoems.entries[allPoems.entries.length - 1].title_slug);
      }
    }
  }, [props.history]);

  const toggleDarkMode = () => {
    if (darkModeIsActive === false) {
      document.body.style.backgroundColor = 'black';
      document.querySelector('html').style.backgroundColor = 'black';
    } else {
      document.body.style.backgroundColor = null;
      document.querySelector('html').style.backgroundColor = null;
    }

    setDarkModeIsActive(!darkModeIsActive);
  }

  useEffect(() => {
    if (initialised === false) {
      setInitialised(true);

      if (darkModeIsActive === true) {
        document.body.style.backgroundColor = 'black';
        document.querySelector('html').style.backgroundColor = 'black';
      } else {
        document.body.style.backgroundColor = null;
        document.querySelector('html').style.backgroundColor = null;
      }

      if (pathname === '/order' || pathname === '/privacy-policy') {
        setGuideIsActive(false);
      }

      const getSiteData = async () => {
        try {
          // because we're async we have to await. This means it can do its own thing and happen whenever without blocking the main thread
          const apiKey = process.env.REACT_APP_API_KEY;

          const fetchPoemsData = () => {
            fetch(`${cockpitUrl}/api/collections/get/poems?token=${apiKey}`)
              .then(response => response.json())
              .then(response => {
                let modifiedResponse = response;
                if (modifiedResponse.entries) {
                  const frontCover = {
                    title_slug: 'start',
                    title: '',
                    text: logoToParse
                  };
                  modifiedResponse.entries.unshift(frontCover);
                }
                setPoems(modifiedResponse);
                if (response.entries) {
                  if (response.entries[0]) {
                    if (response.entries[0].title_slug) {
                      if (pathname === '/' || pathname === '/poem') {
                        props.history.push(`/poem/${response.entries[0].title_slug}`);
                      } else {
                        handleChangeActivePoem(response);
                      }
                    }
                  }
                }
              })
              .catch(error => {
                console.log(error);
              })
          }

          fetchPoemsData();

          const fetchGuidesData = () => {
            fetch(`${cockpitUrl}/api/collections/get/guides?token=${apiKey}`)
              .then(response => response.json())
              .then(response => {
                setGuides(response);
              })
              .catch(error => {
                console.log(error);
              })
          }

          fetchGuidesData();

          const fetchPreOrderData = () => {
            fetch(`${cockpitUrl}/api/singletons/get/preorder?token=${apiKey}`)
              .then(response => response.json())
              .then(response => {
                setPreOrder(response);
              })
              .catch(error => {
                console.log(error);
              })
          }

          fetchPreOrderData();

        }
        catch (error) {
          console.log(error)
        }
      }
      getSiteData();
      onWindowResize();
    }
    window.addEventListener('resize', onWindowResize);

    if (poems.entries) {
      handleChangeActivePoem(poems);
    }

    return () => {
      window.removeEventListener('resize', onWindowResize);
    }
  },
  [poems, initialised, handleChangeActivePoem, darkModeIsActive, onWindowResize, props.history, pathname]);

  let wheelTimeout;

  const onWheelTimeout = () => {
    setScrollDirection(0);
    onWindowResize();
  }

  const handleWheel = (e) => {
    if (e.deltaY) {
      clearTimeout(wheelTimeout);
      let newScrollDirection = e.deltaY > 0 ? 1 : -1;
      newScrollDirection = e.deltaY === 0 ? 0 : newScrollDirection;
      setScrollDirection(newScrollDirection);
      wheelTimeout = setTimeout(onWheelTimeout, 900);
    }
  }

  const handleSwipedUp = () => {
    clearTimeout(wheelTimeout);
    setScrollDirection(1);
    wheelTimeout = setTimeout(onWheelTimeout, 1200);
  }

  const handleSwipedDown = () => {
    clearTimeout(wheelTimeout);
    setScrollDirection(-1);
    wheelTimeout = setTimeout(onWheelTimeout, 1200);
  }

  const handleSwiping = (e) => {
    clearTimeout(wheelTimeout);
    let newScrollDirection = e.deltaY < 0 ? 1 : -1;
    newScrollDirection = e.deltaY === 0 ? 0 : newScrollDirection;
    setScrollDirection(newScrollDirection);
    wheelTimeout = setTimeout(onWheelTimeout, 1200);
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwipedDown: () => handleSwipedDown(),
    onSwiping: (e) => handleSwiping(e)
  });

  const closeMenu = () => {
    setMenuIsOpen(false);
  }

  const handleMenuButtonClick = () => {
    setMenuIsOpen(!menuIsOpen);
  }

  const handlePoemScrollAmountChange = (scrollAmount, scrollHeight) => {
    setPoemScrollAmount(scrollAmount);
    setPoemScrollHeight(scrollHeight);
  }

  const toggleHighlighterIsActive = () => {
    setHighlighterIsActive(!highlighterIsActive);
  }

  return (
    <div
      className={`App full-height${disableScrollRouteChange === true ? ' app--scroll--change' : ''}${
        window.USER_IS_TOUCHING === true ? ' user-is-touching' : ''
      } motion-is-enabled${
        darkModeIsActive === true && ' dark-mode'
      }`}
      onMouseMove={showUI}
      onClick={(e) => {
        onLinkClick(e);
      }}
      onTouchStart={onFirstTouch}
      {...swipeHandlers}
    >
      <Header
        darkModeIsActive={darkModeIsActive}
        pathName={router.pathname}
        activePoemIndex={activePoemIndex}
        poemsLength={poems.entries ? poems.entries.length : -1}
        poemScrollAmount={poemScrollAmount}
        poemScrollHeight={poemScrollHeight}
        windowDimensions={windowDimensions}
        setActivePoemIndex={setActivePoemIndex}
        switchPoem={switchPoem}
        poems={poems}
      />
      <MenuButton
        handleMenuButtonClick={handleMenuButtonClick}
        menuIsOpen={menuIsOpen}
      />
      <AnimatePresence>
        {
          menuIsOpen === true ?
            <Menu
              highlighterIsActive={highlighterIsActive}
              handleMenuButtonClick={handleMenuButtonClick}
              toggleHighlighterIsActive={toggleHighlighterIsActive}
              darkModeIsActive={darkModeIsActive}
              toggleDarkMode={toggleDarkMode}
              setGuideIsActive={setGuideIsActive}
              guideIsActive={guideIsActive}
              pathname={pathname}
            /> : ''
        }
      </AnimatePresence>
      <Switch>
        <Route exact path="/order" render={(props) => (
          <PreOrder preorder={preorder} history={props.history} windowDimensions={windowDimensions} />
        )} />
        <Route exact path="/privacy-policy" render={(props) => (
          <PrivacyPolicy history={props.history} windowDimensions={windowDimensions} />
        )} />
        <Route path="/" render={() => (
          <div
            className={`sections__wrapper${menuIsOpen === true ? ' sections__wrapper--menu--open' : ''}`}
            onClick={closeMenu}
            onWheel={handleWheel}
            {...swipeHandlers}
          >
            <AnimatePresence exitBeforeEnter initial={false}>
              <Switch>
                  {
                    poems.entries ?
                      poems.entries[0] ?
                        poems.entries.map(
                          (poem, i) => (
                            <Route path={`/poem/${poem.title_slug}`} key={i} render={(props) => (
                              <Poem
                                {...props}
                                highlighterIsActive={highlighterIsActive}
                                poem={poem ? poem : ''}
                                prevPoemPath={prevPoemPath}
                                nextPoemPath={nextPoemPath}
                                activePoemIndex={activePoemIndex}
                                pathName={router.pathname}
                                scrollDirection={scrollDirection}
                                setScrollRouteChangeTimeout={setScrollRouteChangeTimeout}
                                disableScrollRouteChange={disableScrollRouteChange}
                                handlePoemScrollAmountChange={handlePoemScrollAmountChange}
                                windowDimensions={windowDimensions}
                                socketEmit={socketEmit}
                                socketEndpoint={ENDPOINT}
                                darkModeIsActive={darkModeIsActive}
                              />
                            )} />
                          )
                        )
                        : '' : ''
                  }
                </Switch>
            </AnimatePresence>
          </div>
          )}
        />
      </Switch>
      <AnimatePresence>
        {
          guideIsActive === true && (
            <Guides guides={guides} setGuideIsActive={setGuideIsActive} windowDimensions={windowDimensions} />
          )
        }
      </AnimatePresence>
    </div>
  );
}

const App = (props) => {

  const [isInitialised, setIsInitialised] = useState(false);
  const socket = io(ENDPOINT, {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "my-custom-header"
    }
  });

  const socketEmit = (customEvent, data) => {
    socket.emit(customEvent, data);
  }

  useEffect(() => {
    if (isInitialised === false) {
      setIsInitialised(true);
      socket.connect();
    }

    socket.on("drawing", data => {
      drawToCanvasReceived(data)
    });

    socket.on("connect", data => {
    });

    return () => {
      // socket.disconnect();
    };
  }, [socket, isInitialised]);

  return (
    <Route path="/" render={(props) => (
      <Content {...props} socketEmit={socketEmit} />
    )} />
  )
};

export default App;
