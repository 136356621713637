import { useEffect, useCallback } from 'react';
import { CircleSlider } from "react-circle-slider";
import * as _ from 'underscore';

const Header = (props) => {

  const { activePoemIndex, poemsLength, poemScrollAmount, poemScrollHeight, darkModeIsActive, windowDimensions, switchPoem, poems } = props;

  const drawIcon = useCallback(() => {
    const r = 30;
    const c = r * 2;
    const w = c * 1.5;
    const center = w * 0.5;

    const canvas = document.querySelector('.header__icon');
    const ctx = canvas.getContext('2d');
    canvas.width = w;
    canvas.height = w;

    if (darkModeIsActive === true) {
      ctx.strokeStyle = '#fff';
      ctx.fillStyle = '#fff';
    } else {
      ctx.strokeStyle = '#000';
      ctx.fillStyle = '#000';
    }

    const drawCircle = (ctxToDraw) => {
      ctxToDraw.beginPath();
      ctxToDraw.arc(w / 2, w / 2, r, 0, 2 * Math.PI);
      ctxToDraw.lineWidth = 3;
      ctxToDraw.stroke();
    }

    const drawPoint = (angle, distance, point_size, ctxToDraw) => {
      var x = center + r * Math.cos(-angle * Math.PI / 180) * distance;
      var y = center + r * Math.sin(-angle * Math.PI / 180) * distance;

      ctxToDraw.beginPath();
      ctxToDraw.arc(x, y, point_size, 0, 2 * Math.PI);
      ctxToDraw.fill();
    }

    //clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // draw large circle
    drawCircle(ctx);
    if (poemsLength && poemsLength !== 0) {
      const degreesPerPoem = 360 / poemsLength;

      let currentPoemAmount = 0;
      if (poemScrollAmount !== 0 && poemScrollHeight !== 0) {
        currentPoemAmount = (degreesPerPoem / poemScrollHeight) * poemScrollAmount;
      }

      drawPoint((activePoemIndex * degreesPerPoem + currentPoemAmount) * -1, 1, 9, ctx);

      let dataUrl = canvas.toDataURL();

      const darkModeIsActiveButFaviconShouldBeLightMode = (darkModeIsActive === true && window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches);
      const darkModeIsInactiveButFaviconShouldBeDarkMode = (darkModeIsActive === false && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

      if (darkModeIsActiveButFaviconShouldBeLightMode || darkModeIsInactiveButFaviconShouldBeDarkMode) {
        const newCanvas = document.createElement('canvas');
        const newCtx = newCanvas.getContext('2d');
        newCanvas.width = w;
        newCanvas.height = w;
        if (darkModeIsInactiveButFaviconShouldBeDarkMode) {
          //make dark mode version for favicon
          newCtx.strokeStyle = '#fff';
          newCtx.fillStyle = '#fff';
          dataUrl = newCanvas.toDataURL();
        } else if (darkModeIsActiveButFaviconShouldBeLightMode) {
          //make daytime version for favicon
          newCtx.strokeStyle = '#000';
          newCtx.fillStyle = '#000';
        }
        drawCircle(newCtx);
        drawPoint((activePoemIndex * degreesPerPoem + currentPoemAmount) * -1, 1, 9, newCtx);
        dataUrl = newCanvas.toDataURL();
      }

      const iconTag = document.querySelector('link[rel=icon]');
      if (iconTag) {
        iconTag.href = dataUrl;
      }
    }
  }, [activePoemIndex, poemsLength, poemScrollAmount, poemScrollHeight, darkModeIsActive]);

  const drawIconThrottled = _.throttle(drawIcon, 60);

  useEffect(() => {
    drawIconThrottled();
  }, [drawIconThrottled]);

  return (
    <header className="header">
      <div className="header__inner">
        <canvas className="header__icon" />
        <div className="header__control__wrapper">
          {
            poemsLength > 0 &&
            <CircleSlider
              value={activePoemIndex === poemsLength + 1 ? 0 : activePoemIndex}
              onChange={_.throttle((e) => { switchPoem(poems, e) }, 60)}
              min={0}
              max={poemsLength + 1}
              size={windowDimensions.width >= 500 ? 60 : 36}
              circleWidth={windowDimensions.width >= 500 ? 12 : 2}
              knobRadius={windowDimensions.width >= 500 ? 28 : 16}
              progressWidth={12}
              progressColor="#00ff00"
              circleColor="#ff00cc"
            />
          }
        </div>
      </div>
    </header>
  )
}

export default Header;