import { useState } from 'react';
import { assetsPath } from '../utils/cockpit.js';
import parse from 'html-react-parser';
import PreOrderPaypal from '../components/PreOrderPaypal.js';

const Gallery = ({ gallery }) => {
  return (
    <div className="gallery__wrapper">
      <div className="gallery">
        {
          gallery.map(
            (item, i) => (
              <div
                className="gallery__item"
                key={i}
              >
                <img
                  className="gallery__item__img"
                  src={assetsPath + item.path}
                  alt={item.meta.title}
                  onLoad={(e) => {
                    e.target.classList.add('loaded');
                  }}
                />
              </div>
            )
          )
        }
      </div>
    </div>
  )
}

const PreOrder = (props) => {

  const { preorder, history, windowDimensions } = props;
  const { gallery, video, info, price, paypal_client_id, thank_you_text } = preorder;
  const [paymentStatusIsPaid, setPaymentStatusIsPaid] = useState(false);
  const [buyIsActive, setBuyIsActive] = useState(false);
  const [noteIsActive, setNoteIsActive] = useState(false);
  const { height } = windowDimensions;

  const updatePaymentStatusToPaid = () => {
    setPaymentStatusIsPaid(true);
  }

  return (
    <div
      className="popup popup--pre-order"
      style={{
        height: (height - 140) + 'px'
      }}
    >
      <button
        onClick={() => {history.goBack()}}
        title="home"
        className="popup__button--close button--nav button--nav--close"
      >
        <div className="button--nav__inner">
          <div className="button--nav__line button--nav__line--0"></div>
          <div className="button--nav__line button--nav__line--1"></div>
        </div>
      </button>
      <div className="popup__inner">
        {
          gallery &&
          <Gallery gallery={gallery} />
        }
        {
          video &&
          <video
            className="preorder__video"
            src={assetsPath + video}
            onCanPlay={(e) => {e.target.classList.add('loaded')}}
            muted
            loop
            playsInline
            autoPlay
          />
        }
        <div className="pre-order__price">£{price}</div>
        {
          paypal_client_id && buyIsActive === true ?
            <PreOrderPaypal
              price={price}
              updatePaymentStatusToPaid={updatePaymentStatusToPaid}
              clientId={paypal_client_id}
              paymentStatusIsPaid={paymentStatusIsPaid}
              thankYouText={thank_you_text}
              noteIsActive={noteIsActive}
            /> : ''
        }
        {
          paypal_client_id && buyIsActive === false ?
            <div className="pre-order__buy-buttons">
            <button
              className="pre-order__buy-button"
              aria-label="buy"
              onClick={() => {
                setBuyIsActive(true);
                setNoteIsActive(false);
              }}
            >for yourself</button>
            <button
              className="pre-order__buy-button"
              aria-label="buy"
              onClick={() => {
                setBuyIsActive(true);
                setNoteIsActive(true);
              }}
            >for someone else</button>
            </div>
          : ''
        }
        <div className="pre-order__info">
          { info && parse(info) }
        </div>
      </div>
    </div>
  )
}

export default PreOrder;