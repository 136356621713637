import { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import parse from 'html-react-parser';
import * as _ from 'underscore';
import { PoemHighlighter } from '../components/PoemHighlighter.js';

const Poem = (props) => {

  const { scrollDirection, prevPoemPath, nextPoemPath, poem, handlePoemScrollAmountChange, highlighterIsActive, windowDimensions, socketEmit } = props;
  const { height } = windowDimensions;
  const { title, text, section_title } = poem;
  const [scrollAmount, setScrollAmount] = useState(0);
  const [poemInitialised, setPoemInitialised] = useState(false);
  const [sectionLength, setSectionLength] = useState(0);
  const [localScrollAmount, setLocalScrollAmount] = useState(0);

  useEffect(() => {

    if (poemInitialised === false) {
      setPoemInitialised(true);
      const direction = props.scrollDirection;
      const section = document.querySelector('.section__inner');
      if (section) {
        if (direction === -1) {
          const sectionHeight = section.offsetHeight;
          if (sectionHeight >= height) {
            setScrollAmount(sectionHeight - height);
            handlePoemScrollAmountChange(sectionHeight - height, sectionHeight);
            setLocalScrollAmount(sectionHeight - height);
            section.scrollTop = section.scrollHeight - section.offsetHeight - 1;
          } else {
            setScrollAmount(0);
            handlePoemScrollAmountChange(0, sectionHeight);
            setLocalScrollAmount(0);
            section.scrollTop = 0;
          }
        } else {
          const sectionHeight = section.offsetHeight;
          setScrollAmount(0);
          handlePoemScrollAmountChange(0, sectionHeight);
          setLocalScrollAmount(0);
          section.scrollTop = 0;
        }
      }
    }
    if (document.querySelector('.section__inner')) {
      setSectionLength(document.querySelector('.section__inner').offsetHeight);
    }

  }, [poemInitialised, props.scrollDirection, setSectionLength, handlePoemScrollAmountChange, height]);

  const onMouseWheel = (event) => {
    if (window.USER_IS_TOUCHING !== true) {
      const prevScrollAmount = scrollAmount;
      const newScrollAmount = prevScrollAmount + event.deltaY;

      const section = document.querySelector('.section__inner');

      if (section) {
        setSectionLength(section.offsetHeight);

        if (props.disableScrollRouteChange === false) {
          if (newScrollAmount > 0 && newScrollAmount < sectionLength) {
            setScrollAmount(newScrollAmount);
            handlePoemScrollAmountChange(newScrollAmount, sectionLength);
          } else if (newScrollAmount < 0) {
            props.history.push(prevPoemPath);
            props.setScrollRouteChangeTimeout();
          } else if (newScrollAmount >= sectionLength - height * 0.75) {
            props.history.push(nextPoemPath);
          }
        } else {
          if (props.scrollDirection === -1) {
            setScrollAmount(sectionLength - height)
          }
        }
      }
    }
  }

  const onMouseWheelThrottled = _.throttle((e) => {onMouseWheel(e)}, 10);

  const handleScroll = (e) => {

    let sectionInner = e.target;
    while (sectionInner.className.indexOf('section__inner') === -1) {
      sectionInner = sectionInner.parentNode;
    }

    if (!sectionInner) { return }
    if (sectionInner.scrollTop >= sectionInner.scrollHeight - height * 0.5) {
      if (localScrollAmount < sectionInner.scrollTop) {
        props.history.push(nextPoemPath);
      }
    }
    if (sectionInner.scrollTop === 0) {
      if (localScrollAmount > sectionInner.scrollTop) {
        props.history.push(prevPoemPath);
        props.setScrollRouteChangeTimeout();
      }
    }
    setLocalScrollAmount(sectionInner.scrollTop);
    setScrollAmount(sectionInner.scrollTop);
  }

  const handleSwipedDown = () => {
    //prev
    if (localScrollAmount <= 0) {
      props.history.push(prevPoemPath);
    }
  }

  const handleSwipedUp = () => {
    //next
    const section = document.querySelector('.section__inner');
    if (section.scrollTop >= section.scrollHeight - height) {
      props.history.push(nextPoemPath);
    } else if (section.offsetHeight < height || section.scrollHeight === section.offsetHeight) {
      props.history.push(nextPoemPath);
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwipedDown: () => handleSwipedDown()
  });

  return (
    <motion.div
      key={{poem}}
      initial={{ y: scrollDirection !== -1 ? height / 2 : -height - (sectionLength * 2), opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: scrollDirection === -1 ? height / 2 : -height - sectionLength, opacity: 0 }}
      transition={{
        duration: 0.6,
        ease: 'easeInOut'
      }}
      style={{
        height: window.USER_IS_TOUCHING === true ? height + 'px' : '',
        minHeight: window.USER_IS_TOUCHING !== true ? height + 'px' : ''
      }}
      className={`section section--poem${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
      onWheel={onMouseWheelThrottled}
      {...swipeHandlers}
    >
      <div
        className="section__inner"
        data-poem={poem.title_slug}
        onScroll={handleScroll}
        style={{
          top: window.USER_IS_TOUCHING === true ? 0 : 0 - scrollAmount + 'px',
          maxHeight: window.USER_IS_TOUCHING === true ? height + 'px' : null,
          overflowY: window.USER_IS_TOUCHING === true ? 'scroll' : 'unset'
        }}
      >
        <div className="poem__content">
          <div className="poem__content__inner">
            {
              title && title !== '' &&
              <h2 className={`poem__title${section_title === true ? ' poem__title--large font--heading--large' : ' font--heading'}`}>{title}</h2>
            }
            <div className="poem__text__outer">
              <div className="poem__text__wrapper">
                {text ? parse(text) : ''}
              </div>
              <AnimatePresence>
                {
                  highlighterIsActive &&
                    <PoemHighlighter poem={poem} windowDimensions={windowDimensions} socketEmit={socketEmit} scrollAmount={scrollAmount} />
                }
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )

}

export default Poem;