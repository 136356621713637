import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const MenuButton = (props) => {

  const { handleMenuButtonClick, menuIsOpen } = props;

  return (
    <button
      id="menuButton"
      aria-label={`${menuIsOpen === true ? 'Close' : 'Open'} Menu`}
      className={`button--nav ${menuIsOpen === true ? 'menu-button--close button--nav--close' : 'menu-button--open'}`}
      onClick={handleMenuButtonClick}
    >
      <div className="button--nav__inner">
        <div className="button--nav__line button--nav__line--0"></div>
        <div className="button--nav__line button--nav__line--1"></div>
      </div>
    </button>
  )
}

const Menu = (props) => {

  const { toggleHighlighterIsActive, highlighterIsActive, toggleDarkMode, setGuideIsActive, guideIsActive, pathname, handleMenuButtonClick } = props;

  const handleClickHighlighter = () => {
    toggleHighlighterIsActive();
  }

  return (
    <motion.nav
      id="menu"
      initial={{
        opacity: 0
      }}
      animate={{
        opacity: 1
      }}
      exit={{
        opacity: 0
      }}
      transition={{
        duration: .2
      }}
    >
      <div className="menu__inner">
        <ul className="menu__list">
          <li className="menu__item">
            <button
              className="menu__item__link"
              onClick={() => {
                setGuideIsActive(true);
                handleMenuButtonClick();
              }}
            >guide</button>
          </li>
          <li className="menu__item">
            <Link
              className="menu__item__link"
              to="/order"
              onClick={() => {
                setGuideIsActive(false);
                handleMenuButtonClick();
              }}
            >order</Link>
          </li>
          {
            pathname.indexOf('/poem') > -1 && guideIsActive === false &&
            <li className="menu__item">
              <button className={`menu__item__link${highlighterIsActive ? ' menu__item__link--highlighted' : ''}`} onClick={handleClickHighlighter}>
                <span className="menu__item__link--mark__inner">mark</span>
              </button>
            </li>
          }
          <li className="menu__item">
            <button
              className="menu__item__button menu__item__button--dark-mode"
              onClick={toggleDarkMode}
              aria-label="Toggle dark mode"
            >
              <div className="menu__item__button--dark-mode__inner"></div>
            </button>
          </li>
        </ul>
      </div>
    </motion.nav>
  )
}

export { Menu, MenuButton };