import Guide from './Guide.js';

const Guides = (props) => {

  const { guides, setGuideIsActive, windowDimensions } = props;

  return (
    <div
      className="popup popup--guides"
      style={{
        height: (windowDimensions.height - 140) + 'px'
      }}
    >
      <button
        className="popup__button--close button--nav button--nav--close"
        onClick={() => {
          setGuideIsActive(false)
        }}
      >
        <div className="button--nav__inner">
          <div className="button--nav__line button--nav__line--0"></div>
          <div className="button--nav__line button--nav__line--1"></div>
        </div>
      </button>
      {
        guides.entries && (
          <div className="popup__inner guides__inner">
            {
              guides.entries[0] &&
                guides.entries.map(
                  (guide, i) => (
                    <Guide guide={guide} key={i} />
                  )
                )
            }
          </div>
        )
      }
    </div>
  )
}

export default Guides;